.hamburger-navbar {
    display: none;
}

.hamburger-navbar-content {
    display: none;
}

/*  Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    .hamburger-navbar {
        display: flex;
        align-items: center;
        margin-left: 1em;
    }

    .hamburger-navbar-content {
        display: flex;
        flex-direction: column;
    }
}

.hamburger-navbar-item {
    color: var(--primary);
    font-weight: 500;

    padding: 1em;
    transition: all 0.2s ease-in-out;
}

.hamburger-navbar-item:hover {
    background: var(--primary-faded);
}

.hamburger-navbar-item.selected {
    background: var(--primary);
    color: white;
}



/* Animation from: https://codepen.io/designcouch/pen/Atyop */
.hamburger-icon {
    background: transparent;
    width: 60px;
    height: 45px;
    position: relative;
    margin: 0 auto;
    -webkit-transform: scale(0.65);
    -moz-transform: scale(0.65);
    -o-transform: scale(0.65);
    transform: scale(0.65);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}


.hamburger-icon span {
    display: block;
    position: absolute;
    height: 7px;
    width: 50%;
    background: var(--primary);
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.hamburger-icon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
}

.hamburger-icon span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
}

.hamburger-icon span:nth-child(1), .hamburger-icon span:nth-child(2) {
    top: 0px;
}

.hamburger-icon span:nth-child(3), .hamburger-icon span:nth-child(4) {
    top: 18px;
}

.hamburger-icon span:nth-child(5), .hamburger-icon span:nth-child(6) {
    top: 36px;
}

.hamburger-icon.open span:nth-child(1),.hamburger-icon.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger-icon.open span:nth-child(2),.hamburger-icon.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hamburger-icon.open span:nth-child(1) {
    left: 5px;
    top: 7px;
}

.hamburger-icon.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
}

.hamburger-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}

.hamburger-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}

.hamburger-icon.open span:nth-child(5) {
    left: 5px;
    top: 29px;
}

.hamburger-icon.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 29px;
}

