.container {
    display: grid;
    grid-template-areas:
        "sidebar search"
        "sidebar children";
    grid-template-columns: 300px minmax(0, 1fr);
    grid-template-rows: auto 1fr;
    gap: 16px;
    margin-bottom: 1em;
}

.sidebar {
    grid-area: sidebar;
}

.search {
    grid-area: search;
    overflow: auto;
}

.children {
    grid-area: children;
}

/*  Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    .container {
        grid-template-areas:
            "sidebar"
            "search"
            "children";

        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: auto auto auto;
    }
}



