
.footer-wrapper {
    background: var(--primary);
    color: white;
}

.app-footer {

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.5em 1em;
}

.app-footer .footer-icons {
    display: flex;
    align-items: center;
}

.app-footer .footer-icon {
    color: white;
}

.app-footer .footer-text {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 0.75em;
    text-align: center;
}

.app-footer .footer-text :not(:first-child) {
    padding-left: 8px;
    border-left: 1px solid white;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .app-footer {
        font-size: 0.85em;
    }

    .app-footer .footer-icon {
        padding: 8px;
    }
}

/*  Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    .app-footer {
        padding-top: 1em;
        flex-direction: column;
        gap: 4px;
    }

    .app-footer .footer-icon:first-child {
        padding-left: 0;
    }
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .app-footer .footer-text {
        align-items: stretch;
    }

    .app-footer .footer-text > *{
        display: flex;
        align-items: center;
    }
}

