.resources {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.resource {
    display: grid;
    align-items: center;
    grid-template-areas: "header sponsor favorite";
    grid-template-columns: 1fr auto auto;
    gap: 16px;
    padding: 1em;
    border-radius: 20px;

    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.resource:hover {
    transform: scale(1.025);
}

.resource .header {
    grid-area: header
}

.resource .title {
    word-break: break-word;
    color: var(--primary);
    font-size: 1.1em;
}

.resource .description {
    color: #B7B7B7;
    font-weight: 500;
    display: block;
    margin: 0.25em 0;
}

.resource .sponsor {
    grid-area: sponsor;
}

.resource .favorite {
    grid-area: favorite;
    font-size: 2em;
    color: #B7B7B7;
    transition: color 0.3s ease-in-out;
}

.resource .favorite.selected,
.resource .favorite:hover {
    color: var(--primary);
}


.downloadButton {
    font-size: 0.75em !important;
    padding: 0.25em 2em !important;
    margin-top: 0.5em;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .resource .sponsor {
        display: none;
    }

    .resource {
        grid-template-columns: 1fr auto;
        grid-template-areas:
            "header favorite"
    }
}