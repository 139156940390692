.center {
    display: flex;
    justify-content: center;
}


.centerdiv {
    padding : 1em;
    display: flex;
    justify-content: center;
}

.error {
    color: var(--error);
    font-size: 0.75em;
    margin: 0.75em;
}
.centerdivresized {
    padding : 1em;
    display: flex;
    justify-content: center;
}

@media (max-width: 575.98px) {
    .centerdivresized {
        padding : 1em;
        display: flex;
        justify-content: center;
        transform:scale(0.77);-webkit-transform:scale(0.77)
    }
  } 