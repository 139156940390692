.button {
    grid-area: sponsor;
    display: inline-flex;
    align-items: center;
    grid-gap: 8px;

    padding: 0.25em 1em;
    border-radius: 50px;

    box-shadow: 0 0 20px -10px var(--primary);
}


.button > img {
    width: 2em !important;
}


.button .left {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
}

.button strong {
    background: white;
    color: #bbb;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.45em;
    outline: none !important;
}

.button small {
    color: var(--primary);
    font-size: 0.60em;
    font-weight: bold;
}

