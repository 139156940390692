.topbarContainer {
  box-shadow: 0 1px 10px -8px rgba(0, 0, 0, 0.75);
  z-index: 10;

}

.topbar {
  display: flex;
}

.topbar .topbarIcon {
  display: none;
}

.links {
  align-self: center;
  color: var(--text-light);
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 0.3em;
  cursor: pointer;
}

.cartIcon {
  width: 30px;
  height: 30px;
}

.shoppingCartLink {
  align-self: center;
  color: white;
  position: relative;
  display: flex;
}

.favLink {
  align-self: center;
  color: white;
  position: relative;
  display: flex;
  cursor: pointer;
}

.trainingsLinkMobile {
  align-self: center;
  position: relative;
  display: flex;
  cursor: pointer;
  color: var(--text-light);
  display: none;
}

.shoppingCartSize {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: darkorange;
  color: white;
  font-size: 0.7em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authButtons {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 1em;
  font-size: 0.85em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  height: fit-content;
  align-self: center;
}

.topbarCollapsible {
  position: absolute;
  background: var(--background-secondary);
  z-index: 10;
  width: 100%;
  box-shadow: 0 1px 10px -8px rgba(0, 0, 0, 0.75);
}

.iconButton {
  width: 36px;
  height: 36px;
  padding: 0.3em !important;

  color: var(--primary) !important;
  background: var(--primary) !important;
  position: relative;

  .icon {
    color: white !important;
  }

  .partnerIconContainer {
    position: absolute;
    z-index: 1;
    top: -3px;
    right: -2px;
    background-color: var(--discount);
    border-radius: 50% !important;
    width: 13px;
    height: 13px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .partnerIcon {
    color: white !important;
    width: 80%;
    height: 80%;
  }

  .dropdownIconContainer {
    position: absolute;
    z-index: 1;
    bottom: -3px;
    right: -2px;
    background-color: var(--background-dark);
    border-radius: 50% !important;
    width: 13px;
    height: 13px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .dropdownIcon {
    color: var(--text-secondary) !important;
    width: 100%;
    height: 100%;
  }
}

.settingsMenu {
  position: relative;
}

.menuContainer {
  position: absolute;
  right: 0;
  z-index: 1;
}

.menu {
  margin-top: 5px;
  box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  background-color: white;
}

.menuItem {
  display: flex;
  flex-direction: row;
  font-size: 0.9em !important;
  padding: 0.5em 0.7em !important;
  cursor: pointer;
  transition: background-color 0.3s;
  align-items: center;
  gap: 1em;
  font-weight: bold !important;
  justify-content: flex-end !important;
  text-transform: none !important;
  font-family: "Montserrat", sans-serif !important;
  color: var(--text-secondary) !important;
  background-color: transparent !important;
  width: 100% !important;
}

.menuItem:hover {
  background-color: #f5f5f5 !important;
}

.menuItemBottomDivision {
  display: flex;
  margin: auto;
  height: 1px;
  width: 90%;
  background-color: var(--background-dark);
}

.label {
  text-wrap: nowrap;
}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .authButtons {
    align-items: stretch;
    flex-direction: row;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .topbar .topbarLogo {
    display: none;
  }
  .topbar .topbarIcon {
    display: flex;
  }
}

@media (max-width: 1200px) {
  .settingsMenu {
    align-self: center;
  }
  .trainingsLinkDesktop {
    display: none !important;
  }
  .trainingsLinkMobile {
    display: flex;
    padding-left: 0.5em;
  }
}
