.auth-section {
    margin: auto;
    padding: 1em;
    box-sizing: border-box;
    text-align: center;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .auth-section {
        width: 100%;
        font-size: 0.85em;
    }
}