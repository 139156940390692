.link {
    display: flex;
    height: 100%;
    transition: transform 0.2s ease-in-out;
}

.link:hover {
    transform: scale(1.025);
}

.card {
    flex-grow: 1;
    padding: 1em;
    display: flex;
    flex-direction: column;
}

.card h2 {
    flex-grow: 1;
}

.card span {
    align-self: flex-end;
    color: var(--primary);

    min-width: 4em;
    min-height: 4em;

    max-width: 5em;
    max-height: 5em;
    display: flex;
    align-items: flex-end;
}

.card span > * {
    width: 100%;
    height: 100%;
}

