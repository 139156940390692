.footer-nav footer {
    display: flex;
    flex-wrap: wrap;

    gap: 5vw;
}

.footer-nav {
    background: var(--background-secondary);
    padding: 1em;
    margin-top: 0.5em;
}

.footer-nav .footer-nav-item {
    display: flex;
    flex-direction: column;
    gap: 2px;

    color: var(--primary);
    font-size: 0.8em;
}

.change-language {
    margin-top: -1em;
    margin-left: auto;
    width: 200px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .footer-nav {
        padding: 1em 0;
    }

    .footer-nav footer {
        justify-content: center;
    }

    .footer-nav .footer-nav-item {
        align-items: center;
    }

}
