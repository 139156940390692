.container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 1.5em;
}


.search {
    flex-grow: 1;
    display: flex;
    align-items: center;
    min-width: 200px;
}

/*  Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    .container {
        margin-top: 0;
    }

}
