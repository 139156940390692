.form-control {
    text-align: left;
}

.form-control-with-margin {
    margin-top: 1em;
}

/* Input itself: Icons, container, etc. */
.form-control .form-control-container {
    position: relative;

}


.form-control .form-control-icon {
    position: absolute;
    right: 12px;

    /* Vertically center */
    display: flex; align-items: center;
    top: 50%; transform: translateY(-50%);


    color: var(--primary);
    pointer-events: none;
}

/* Label */
.form-control .form-label {
    display: inline-block;
    color: var(--primary);
    margin: 0 0 0.5em 0;
    font-weight: 500;
}

/* Error / Success */
/*
.form-control.form-control-error .form-input {
    box-shadow: 0 0 5px var(--error);
    border-color: var(--error);
}

.form-control.form-control-success .form-input {
    box-shadow: 0 0 5px var(--success);
    border-color: var(--success);
}

 */


.form-control.form-control-error .form-error-message,
.form-control.form-control-success .form-success {
    text-align: right;
    font-size: 0.75em;
    margin: 0.75em;
}

.form-control .form-error-message {
    color: var(--error);
}

.form-control .form-success {
    color: var(--success);
}




