.form {
    display: flex;
    width: 100%;
}

.input {
    margin: 0;
    flex-grow: 1;
}

.input input {
    padding: 0.75em !important;
    border-radius: 6px 0 0 6px !important;
}

.submit, .submit button {
    display: flex;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0 6px 6px 0 !important;
    width: auto !important;
}

.submit .icon {
    padding: 0.25em;
}