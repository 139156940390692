.detector {
    display: flex;
    gap: 16px;

    border-radius: 6px;
    background: white;

    position: fixed;
    bottom: 16px;
    right: 16px;

    width: 30em !important;
    font-size: 1em;
    padding: 1em;
    box-sizing: border-box;

    z-index: 1000;

    transition: transform 0.2s ease-in-out;
}

.detector.online {
    transform: translateY(300px);
}

.icon {
    color: var(--error);
    min-width: 50px;
    min-height: 50px;
    margin: -1em -0.5em;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .detector {
        width: calc(100% - 32px) !important;
    }
}
