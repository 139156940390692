.error-page-title {
    color: var(--primary);
    font-size: 6em;
    text-align: center;
}

.error-page-subtitle {
    color: var(--text-secondary);
    font-size: 3em;
    text-align: center;
}

.error-page-image {
    width: auto !important;
    max-width: 100%;
    margin: 1em;
}

.error-page {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .error-page-title {
        font-size: 4em;
    }

    .error-page-subtitle {
        font-size: 2em;
    }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .error-page-title {
        font-size: 3em;
    }

    .error-page-subtitle {
        font-size: 1.5em;
    }
}
