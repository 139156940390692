.form-submit-container {
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.form-submit-container.with-margin {
    margin: 1.5em auto 0.5em auto;
}

.form-submit-error,
.form-submit-success {
    width: 100%;
    text-align: center;
    margin-top: 0.75em;

    font-weight: 500;
    white-space: pre-wrap;
}

.form-submit-success {
    color: var(--success);
}

.form-submit-error {
    color: var(--error);
}

.form-submit-btn {
    display: flex;
    align-items: center;
}