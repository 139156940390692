.card2{
    padding: 0.3em;
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
.resource .rightSide {

    flex-grow: 1;
    padding-left: 1rem;

}
.resource {
    display: grid;
    align-items: center;
    grid-template-areas: "header sponsor favorite";
    grid-template-columns: auto auto;
    gap: 16px;
    padding: 0.2em;
    border-radius: 20px;

    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.resource:hover {
    transform: scale(1.025);
}

.resource .header {
    grid-area: header;
    align-items: center;
    vertical-align: middle; 
    display: flex; 
    overflow: auto;
    position: relative;
}
.resource .title {
    padding-top: 1rem;
    word-break: break-word;
    color: #19A4BB; /*var(--primary);*/
    font-size: 1em;
    size: 1rem;
}

.resource .description {
    color: #B7B7B7;
    font-weight: 500;
    display: block;
    margin: 0.25em 0;
    font-size: 0.75em;
}

.resource .sponsor {
    grid-area: sponsor;
}

.resource .favorite {
    grid-area: favorite;
    font-size: 2em;
    color: #B7B7B7;
    transition: color 0.3s ease-in-out;
}

.resource .favorite.selected,
.resource .favorite:hover {
    color:#19A4BB;  /*var(--primary);*/
}


.downloadButton {
    font-size: 0.75em !important;
    padding: 0.25em 2em !important;
    margin-right: 1em !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .resource .sponsor {
        display: none;
    }

    .resource {
        grid-template-columns: 1fr auto;
        grid-template-areas:
            "header favorite"
    }
}