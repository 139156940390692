.simple-modal-container {
    display: flex;
    overflow: auto;
    padding: 1em;
}

.simple-modal-backdrop {
    background: rgba(0, 0, 0, 0.25) !important;
}

.simple-modal {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    padding: 1.5em;
    background: white;
    margin: auto;

    border-radius: 4px;

    position: relative;
}

.simple-modal:focus {
    outline: none;
}

.simple-modal.modal-sm {
    width: 25em;
}

.simple-modal.modal-md {
    width: 45em;
}

.simple-modal.modal-lg {
    width: 65em;
}

.simple-modal .modal-close-icon {
    cursor: pointer;
    position: absolute;
    top: 16px; right: 16px;
    color: var(--primary);
}