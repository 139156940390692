
.brand-name {
    display: flex;
    align-items: center;
}

.brand-name-center {
    justify-content: center;
}

.brand-name h1 {
    margin: 0.5em;
    font-size: 2em;
    display: flex;
    align-items: center;
}

.brand-name-small {
    justify-content: center;
}

.brand-name svg {
    height: 1em;
}

.brand-name-small svg {
    width: 1em;
}

/*  Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    .brand-name {
        margin: 0.25em;
    }
}
