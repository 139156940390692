

.letters {
    display: flex;
    margin: 0.5em 0;
    font-size: 1.5em;
    color: var(--primary);
    font-weight: bold;
}


.letters li {
    flex: 1 1 0;

    display: flex;
    justify-content: center;
    font-size: 0.75em;

    white-space: nowrap;
    margin: 0 0.2em;
}

.letters li > a {
    width: 100%;
    text-align: center;
}

.letters li.selected {
    text-decoration: underline;
}
