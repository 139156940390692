.wrapper{
    display: flex;
    min-height: 500px;
}

.left{
    
    flex: 1;
    padding: 1rem;
}
.left2{
    width:20%;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    /* background-image: url("https://innerbody.imgix.net/MU-Female-Anterior_0.png?w=313&auto=format"); */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
    position: relative;
    padding:1em;
}
.resource {
    align-items: center;
    grid-template-columns: 1fr auto auto;
    gap: 16px;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.resource:hover {
    transform: scale(1.025);
}
.title {
    padding: 0.4rem;
    word-break: break-word;
    color: #19A4BB; /*var(--primary);*/
    font-size: 1em;
    size: 1rem;
}

.description {
    color: #B7B7B7;
    padding: 0.4rem;
    font-weight: 500;
    display: block;
    margin: 0.25em 0;
    font-size: 0.75em;
    white-space: pre-line
}

.img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 500px;
}

.line{
    z-index: 10;
}
.test
{
    width: 50px;
    height: 50px ;
}

.imagem{
    margin-left: auto;
    margin-right: auto;
    max-width:100%;
    max-height: 500px;
    object-fit: contain;
}