body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

   */
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primary: #19A4BB;
  --primary-light: #47b6c8;
  --primary-faded: #E7F5F8;

  --error: #FF6E77;
  --success: #20C9B2;
  --warning: #e3c357;

  --discount: #e07810;
  --discount-light: #e2a365;

  --text-primary: #000;
  --text-secondary: #686868;
  --text-tertiary: #888888;
  --text-light:  #B7B7B7;

  --background-dark: #eee;
  --background-secondary: #fbfbfb;

  color: var(--text-secondary);
}


html, body, #root {
  height: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  font-size: 1em;
  font-weight: inherit;
}

h1, h2, h3 {
  color: var(--primary)
}

h1, h2, h3, p {
  margin: 0;
}

/* was a bad idea to do this */
*:not(.svg-ignore-fill-rule) > svg {
  color: inherit;
  fill: currentColor;
}

input, select, textarea, button{font-family:inherit;}
.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  width: 30rem;
  z-index: 11 ;
  position: fixed ;
  top: 10vh;
  left: calc(50% - 15rem);
}
.backdrop {
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

@media (max-width: 575.98px) {
  .modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 14rem;
    z-index: 11 ;
    position: fixed ;
    top: 8vh;
    left: calc(50% - 7rem);
  }
}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--primary);
}