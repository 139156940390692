.emptyContainer {
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.emptyMessageContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 53% !important;
  justify-content: center;
}

.emptyMessageTitle {
  font-size: 1.1em;
  color: var(--primary);
  font-weight: 700;
}

.emptyMessageContent {
  font-size: 0.8em;
  font-weight: 500;
}

.emptyImage {
  width: 45% !important;
  img {
    max-height: 300px;
    object-fit: contain;
  }
}

@media (max-width: 500px) {
  .emptyContainer {
    flex-direction: column;
  }
  .emptyMessageContainer {
    width: 100% !important;
  }
  .emptyImage {
    width: 100% !important;
  }

  .emptyMessageTitle {
    font-size: 0.9em;
  }

  .emptyMessageContent {
    font-size: 0.6em;
  }
}
