.custom-scrollbar {
    width: 100%;
}

.custom-scrollbar-width{
    width: 98%;
}

.custom-scrollbar > * {
    overflow: auto;
}

.custom-scrollbar ::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 16px;
    background-color: #F5F5F5;
}

.custom-scrollbar ::-webkit-scrollbar
{
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}

.custom-scrollbar ::-webkit-scrollbar-thumb
{
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: var(--primary);
}