.main-navbar {
  display: flex;
  flex-wrap: wrap; /* not ideal, but edge case for admins */

  color: var(--primary);
  font-size: 1.25em;
  font-weight: 500;

  flex-grow: 1;
  margin-left: 0.5em;
  text-align: center;
}

.main-navbar-item svg {
  font-size: 2em;
}

.main-navbar-item {
  display: flex;
  align-items: center;
  padding: 0.75em 1em;

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  position: relative;
}

.main-navbar-item:hover {
  background: var(--primary-faded);
}

.main-navbar-item.selected {
  background: var(--primary);
  color: white;
}

.main-navbar-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;

  padding: 1em;
}

.main-navbar-content > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.main-navbar-button {
  border-radius: 8px;
  margin-bottom: 0.5em;
}

.main-navbar-button a {
  padding: 0.5em 1em;
  color: var(--text-secondary);
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-navbar-link {
  color: var(--primary);
  font-weight: 500;
  margin-left: 1em;
}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .main-navbar {
    margin-left: 0;
  }

  .main-navbar-item {
    padding: 0.75em;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .main-navbar {
    font-size: 1em;
  }

  .main-navbar-content {
    font-size: 0.75em;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .main-navbar-item {
    padding: 0.5em;
  }
}

/*  Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .main-navbar-content {
    display: none;
  }

  .main-navbar {
    display: none;
  }
}

.tabsP {
  width: 100%;
  margin: 0em 0.5em;
}

.disableOverflow {
  overflow-x: unset;
}

.tabsP ul {
  display: flex;
  flex-direction: row;
  gap: 2em;
}

.tabP-item {
  color: var(--text-light);
  font-size: 0.8em;
}

.tabP-item.selected button {
  font-weight: bold;
  color: var(--primary);
  border-bottom: 4px solid var(--primary);
}

.tabP-item button {
  font-weight: 500;
  border-bottom: 4px solid transparent;
  transition: all 0.1s ease-in-out;

  height: 100%;
  width: 100%;
  padding: 0.75em 0;
  background: none;
}

.tabP-item:not(.selected):hover button {
  color: var(--primary);
  border-bottom: 4px solid var(--primary);
  opacity: 0.6;
}
