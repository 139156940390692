.form-control .form-input {
    height: 100%;
    border: 1px solid  var(--background-dark);

    border-radius: 8px;

    width: 100%;

    padding: 1em;
    box-sizing: border-box;

    text-indent: 4px;

    transition: all 0.3s ease-in-out;
}

.form-control .form-input:focus {
    outline: none;
    box-shadow: 0 0 5px var(--primary);
    border-color: var(--primary);
}

.form-control .form-input::placeholder {
    /*color: var(--primary);*/
}

/* Passwords (hide/show) */
.form-input-wrapper {
    position: relative;
}

.form-input-wrapper .visibility-icon {
    color: var(--text-secondary);
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;

    position: absolute;
    right: 16px;

    /* Vertically center */
    display: flex; align-items: center;
    top: 50%; transform: translateY(-50%);
}

.form-input-wrapper .visibility-icon:hover {
    opacity: 1;
}

/* Error / Success */
.form-control.form-control-error .form-input {
    box-shadow: 0 0 5px var(--error);
    border-color: var(--error);
}

.form-control.form-control-success .form-input {
    box-shadow: 0 0 5px var(--success);
    border-color: var(--success);
}

/* Date input Arrows */
.form-control .form-input[type="date"]::-webkit-inner-spin-button,
.form-control .form-input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    background: transparent;
}

.form-control .form-input[type="datetime-local"]::-webkit-inner-spin-button,
.form-control .form-input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    background: transparent;
}

/* Number input Arrows */
.form-control .form-input[type="number"]::-webkit-outer-spin-button,
.form-control .form-input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.form-control .form-input[type="number"] {
    -moz-appearance: textfield;
}
