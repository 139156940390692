.simplefigure {
    height: 120px; /*temporary fix, it is also adjusted in .img*/
    width: 120px;
    border-radius: 10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    display:  inline-block;
    overflow: hidden;
    box-shadow: 0 0 20px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0 0 20px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 20px -10px rgba(0,0,0,0.75);
}

.simplefigure img {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    float: left;


    border-radius: 4px;
}

.simplefigure figcaption {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em 1em;
    height: 9.5em;


    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

.simplefigure .figure-title {
    font-weight: bold;
    color: var(--primary);
    padding-bottom: 0.5em;
}

.simplefigure small {
    color: var(--text-light);

    display: flex;
    align-items: center;
    gap: 8px;
}

.simplefigure small svg {
    font-size: 1.25em;
}