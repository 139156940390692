.form-control select.form-input  {
    min-width: 5.5em !important;
    padding-right: 2em !important;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.form-control select.form-input::-ms-expand {
    display: none;
}
