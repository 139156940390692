.mainNav{
    margin-bottom: 1em;
}

.sidebar svg {
    color: var(--primary);
}

.heading {
    display: flex;
    align-items: center;
}

.heading > svg {
    max-height: 50px;
    max-width: 50px;
    margin-right: 0.5em;
}

.selected {
    color: var(--primary) !important;
    font-weight: bold !important;
}

/* Popular, all, etc */

.mainNav {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.navbarItem {
    display: grid;
    align-items: center;
    grid-template-columns: 30px 1fr;
    gap: 16px;
    padding: 0.75em;
    font-weight: 500;
    color: var(--text-secondary);
}

/* Specialties */

.specialtiesHeader {
    color: var(--primary);
    margin-top: 1em;
    font-weight: 500;
    font-size: 1em;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 8px;
}

.specialtiesHeader svg {
    transition: transform 0.3s;
}

.specialtiesOpen svg {
    transform: rotateZ(180deg);
}


.specialtiesContainer {
    padding: 0 1em;
    margin: 0 -1em; /* box shadow overflow */
}
.specialties {
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 1em 0;  /* box shadow overflow */
}

.specialtyTrigger {
    background: white !important;
    color: var(--text-secondary) !important;
    padding: 0.75em !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
}

.specialty {
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 16px;
    align-items: center;
    font-weight: 500;
}

.specialty img {
    max-width: 100%;
    height: 30px;
    margin: auto;
}


.themes {
    background: none !important;
    padding: 0 1em !important;
}

.themes .specialtyLink {
    width: 100%;
    padding: 1em 0;
    border-top: 1px solid var(--background-dark);
    color: var(--text-secondary);
    font-weight: 500;

    display: grid;
    grid-template-columns: 1fr 2em;
    gap: 8px;
    align-items: center;

}


/*  Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    .heading {
        margin-top: 0.5em !important;
    }

}
